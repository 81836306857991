import React, { useState } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';

function DocumentTypesField({ options, value, setValue }) {
  const [selected, setSelected] = useState(value);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    setValue(newValue);
  };

  return (
    <Box>
      <Autocomplete
        id='document-types-field'
        options={options}
        value={selected}
        sx={{ width: '100%' }}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        disableClearable={true}
        renderInput={(params) => (
          <TextField {...params} required label='Document Types Filter' />
        )}
      />
    </Box>
  );
}

export default DocumentTypesField;
