import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Stack,
  Box,
  Typography,
  Toolbar,
  Divider,
  Button,
  Link,
} from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { deleteChat, getChats } from '@/actions/chat';
import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';
import CHAT_TYPES from '@/components/chatTypes';
import ChatDeleteDialog from '@/components/shared/ChatDeleteDialog';
import { useConfiguration } from '@/providers/ConfigurationContext';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const NEW_CHAT_NAME = 'New Audit';

function ExternalAudits() {
  const user = useUser();
  const configuration = useConfiguration();
  const navigate = useNavigate();
  const [chats, setChats] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    if (user?.entity_id && !chats) {
      getChats(configuration.api, CHAT_TYPES.QUARTERMASTER)
        .then((data) => {
          data.sort(
            (a, b) =>
              new Date(b.updated_at).valueOf() -
              new Date(a.updated_at).valueOf()
          );
          setChats(data);
        })
        .catch((error) => console.log(error));
    }
  }, [user?.entity_id, chats, configuration.api]);

  const handleDeleteChat = async (chat_id) => {
    await deleteChat(configuration.api, chat_id);
    const new_chats = [...chats].filter((chat) => chat.entity_id !== chat_id);

    setChats(new_chats);
  };

  const handleRowSelection = useCallback(
    (value) => {
      setRowSelectionModel(value);
    },
    [setRowSelectionModel]
  );

  const renderName = useCallback((params) => {
    return (
      <Link
        component={RouterLink}
        to={`/quartermaster/externalAudit/${params.id}`}
      >
        {params.row.name}
      </Link>
    );
  }, []);

  const renderStatus = useCallback(({ row }) => {
    if (row.name === NEW_CHAT_NAME) {
      return 'In Progress';
    }
    return 'Complete';
  }, []);

  const convertDate = useCallback(({ value }) => {
    if (!value) {
      return value;
    }
    return new Date(value.replace(' ', 'T') + 'Z');
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
        renderCell: renderName,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        flex: 0.5,
        renderCell: renderStatus,
      },
      {
        field: 'created_at',
        headerName: 'Started',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
      {
        field: 'updated_at',
        headerName: 'Last Updated',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
    ],
    [renderName, convertDate, renderStatus]
  );

  if (!chats) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <Typography variant='h6' sx={{ mr: 4 }}>
          Audits
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() => {
              navigate('/quartermaster/externalAudit/edit');
            }}
            variant='contained'
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            New Audit
          </Button>
          <Button
            onClick={() => {
              navigate({
                pathname: '/quartermaster/externalAudit/edit',
                search: `?audit_id=${rowSelectionModel[0]}`,
              });
            }}
            disabled={rowSelectionModel.length !== 1}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            disabled={rowSelectionModel.length !== 1}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <DataGrid
              autoHeight
              checkboxSelection
              columnHeaderHeight={35}
              getRowId={(row) => row.entity_id}
              columns={columns}
              rows={chats || []}
              onSelectionModelChange={handleRowSelection}
              onRowSelectionModelChange={handleRowSelection}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  sx: {
                    button: {
                      color: 'black',
                    },
                    svg: {
                      color: 'black',
                    },
                  },
                  showQuickFilter: true,
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <ChatDeleteDialog
        key={`delete_${rowSelectionModel[0]?.updated_at}`}
        chat={{ entity_id: rowSelectionModel[0] }}
        open={deleteOpen}
        deleteChat={handleDeleteChat}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default ExternalAudits;
