import React, { useState } from 'react';

import { ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import UploadButton from '../../shared/UploadButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteAsset, insertAsset } from '../../../actions/chatAssets';
import getFile from '../../../util/getFile';
import PromptField from './PromptField';
import { useConfiguration } from '../../../providers/ConfigurationContext';

export default function MultiModalPrompt({
  chat_id,
  prompt,
  image_id,
  error,
  setPrompt,
  setImageId,
  setImageData,
  setError,
  onSubmit,
  ...rest
}) {
  const configuration = useConfiguration();
  const [image, setImage] = useState();
  const [prompt_error, setPromptError] = useState(false);
  const [image_error, setImageError] = useState(false);

  const handleImageChange = async (e) => {
    const image = await getFile(e.target.files[0]);
    const file_name = image.name;

    if (image.base64contents.length > 1024 ** 2 * 8) {
      setImageError('image too big');
      setError(prompt_error || image_error);
    } else {
      setImageError(false);
      setError(prompt_error || image_error);
    }
    const response = await insertAsset(
      configuration.api,
      chat_id,
      file_name,
      image.type,
      image.contents
    );

    setImageId(response.asset_id);
    setImage(image);
    setImageData(image);
  };

  const handleDeleteImage = async () => {
    await deleteAsset(configuration.api, chat_id, image_id);
    setImageId(null);
    setImage(null);
    setImageData(null);
    setImageError(false);
    setError(prompt_error || image_error);
  };

  const handlePromptError = (error) => {
    setPromptError(error);
    setError(prompt_error || image_error);
  };

  return (
    <Grid container spacing={0}>
      <Grid lg={12} md={12} xs={12}>
        <ButtonGroup variant='outlined' color='secondary'>
          <UploadButton
            tooltip={'Image must be smaller than 1 MB'}
            {...rest}
            variant='outlined'
            onChange={handleImageChange}
          />
        </ButtonGroup>
      </Grid>
      {image ? (
        <Grid lg={12} md={12} xs={12}>
          <Stack direction='row' spacing={1} alignItems={'center'}>
            <img
              style={{
                width: '250px',
                height: '250px',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              alt='uploaded'
              src={image.base64contents}
            />
            <IconButton onClick={handleDeleteImage} color='secondary'>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
      ) : undefined}
      {image_error ? (
        <Grid lg={12} md={12} xs={12}>
          <Typography color='red' variant='caption'>
            Image too large
          </Typography>
        </Grid>
      ) : undefined}
      <Grid lg={12} md={12} xs={12}>
        <PromptField
          {...rest}
          value={prompt}
          error={prompt_error}
          setValue={setPrompt}
          setError={handlePromptError}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
}
