const SCORE_CATEGORIES = {
  GOLDEN: 'golden',
  GROWTH: 'growth',
  CRITICAL: 'critical',
};

const SCORE_CATEGORY_DETAILS = {
  [SCORE_CATEGORIES.GOLDEN]: {
    value: SCORE_CATEGORIES.GOLDEN,
    threshold: 80,
    label: 'Golden Standard',
    description: 'Exceptional compliance with no major issues.',
    color: 'green',
  },
  [SCORE_CATEGORIES.GROWTH]: {
    value: SCORE_CATEGORIES.GROWTH,
    threshold: 50,
    label: 'Room for Growth',
    description:
      'Document is generally compliant but needs minor improvements.',
    color: 'yellow',
  },
  [SCORE_CATEGORIES.CRITICAL]: {
    value: SCORE_CATEGORIES.CRITICAL,
    threshold: 0,
    label: 'Critical Overhaul Needed',
    description: 'Significant gaps or issues requiring immediate attention.',
    color: 'red',
  },
};

const getScoreCategoryDetails = (value) => {
  if (value < SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GROWTH].threshold)
    return SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.CRITICAL];
  if (value < SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GOLDEN].threshold)
    return SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GROWTH];
  return SCORE_CATEGORY_DETAILS[SCORE_CATEGORIES.GOLDEN];
};
export default getScoreCategoryDetails;
