import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Button,
  Toolbar,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import { getManifest, deleteManifest } from '@/actions/manifest';
import ManifestDeleteDialog from '../ManifestDeleteDialog';
import toNiceTimestamp from '@/util/toNiceTimestamp';
import { useDocTypes } from '../../../../providers/DocTypesContext';
import AdminManifestChecks from './AdminManifestChecks';

function AdminManifest() {
  const user = useUser();
  const configuration = useConfiguration();
  const navigate = useNavigate();
  const doc_types = useDocTypes();
  const { manifest_id } = useParams();
  const [manifest, setManifest] = useState(null);
  const [loading_manifest, setLoadingManifest] = useState(true);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (user?.entity_id && !manifest) {
      getManifest(configuration?.api, manifest_id)
        .then((data) => {
          setManifest(data);
          setLoadingManifest(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingManifest(false);
        });
    }
  }, [
    configuration?.api,
    user?.entity_id,
    manifest,
    manifest_id,
    setManifest,
    setLoadingManifest,
  ]);

  const handleDeleteManifest = async (manifest_id) => {
    await deleteManifest(configuration.api, manifest_id);
    const doc_type = doc_types.find(
      (doc_type) => doc_type.entity_id === manifest.document_type_id
    );
    navigate({
      pathname: '/admin/manifests',
      search: `?type=${doc_type.code}`,
    });
  };

  if (loading_manifest) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          {manifest.name} Manifest
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() => {
              navigate({
                pathname: '/admin/manifests/edit',
                search: `?manifest_id=${manifest?.entity_id}`,
              });
            }}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />

      <Box padding='15px'>
        <Typography variant='subtitle'>Basic Information</Typography>
        <Divider />
        <br />
        <Grid
          container
          component='dl' // mount a Definition List
          spacing={0}
          sx={{ display: 'table' }}
        >
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>ID:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {manifest?.entity_id}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Created:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {toNiceTimestamp(manifest?.created_at, true)}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Reference Document:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {manifest?.reference_document?.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box padding='15px'>
        <Typography variant='subtitle'>Checks</Typography>
        <AdminManifestChecks manifest={manifest} />
      </Box>

      <ManifestDeleteDialog
        manifest_id={manifest.entity_id}
        deleteManifest={handleDeleteManifest}
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default AdminManifest;
