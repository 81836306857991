export const getInspectionChecks = async (
  api,
  inspection_id,
  document_id,
  manifest_id
) => {
  const resp = await api.get(`/inspection/${inspection_id}/check`, {
    params: { document_id, manifest_id },
  });
  return resp.data;
};

export const getInspectionCheck = async (
  api,
  inspection_id,
  inspection_check_id
) => {
  const resp = await api.get(
    `/inspection/${inspection_id}/check/${inspection_check_id}`
  );
  return resp.data;
};

export const updateInspectionCheck = async (
  api,
  inspection_id,
  inspection_check_id,
  values
) => {
  const resp = await api.put(
    `/inspection/${inspection_id}/check/${inspection_check_id}`,
    values
  );
  return resp.data;
};
