export const getInspections = async (api, type, limit = 50, offset = 0) => {
  const resp = await api.get('/inspection', {
    params: { type, limit, offset },
  });
  return resp.data;
};

export const getInspection = async (api, inspection_id) => {
  const resp = await api.get(`/inspection/${inspection_id}`);
  return resp.data;
};

export const createInspection = async (api, values) => {
  const resp = await api.post(`/inspection/`, values);
  return resp.data;
};

export const updateInspection = async (api, inspection_id, values) => {
  const resp = await api.put(`/inspection/${inspection_id}`, values);
  return resp.data;
};

export const deleteInspection = async (api, inspection_id) => {
  const resp = await api.delete(`/inspection/${inspection_id}`);
  return resp.data;
};

export const runInspection = async (api, inspection_id) => {
  const resp = await api.post(`/inspection/${inspection_id}/run`);
  return resp.data;
};
