export const getInspectionAssets = async (api, inspection_id) => {
  const resp = await api.get(`/inspection/${inspection_id}/asset`);
  return resp.data;
};

export const getInspectionAsset = async (api, inspection_id, asset_id) => {
  const resp = await api.get(`/inspection/${inspection_id}/asset/${asset_id}`);
  return resp.data;
};

export const insertInspectionAsset = async (
  api,
  inspection_id,
  file_name,
  content_type,
  data
) => {
  const resp = await api.post(`/inspection/${inspection_id}/asset`, data, {
    params: { file_name },
    headers: {
      'Content-Type': content_type,
    },
  });
  return resp.data;
};

export const updateInspectionAsset = async (
  api,
  inspection_id,
  asset_id,
  content_type,
  data
) => {
  const resp = await api.put(
    `/inspection/${inspection_id}/asset/${asset_id}`,
    data,
    {
      headers: {
        'Content-Type': content_type,
      },
    }
  );
  return resp.data;
};

export const deleteInspectionAsset = async (api, inspection_id, asset_id) => {
  const resp = await api.delete(
    `/inspection/${inspection_id}/asset/${asset_id}`
  );
  return resp.data;
};
