import round from '@/util/round';

const getDocumentScore = (
  critical_check_count,
  regular_check_count,
  critical_check_passed,
  regular_check_passed
) => {
  let value;
  if (critical_check_count === 0) {
    value = (regular_check_passed / regular_check_count) * 100;
  } else if (critical_check_count !== critical_check_passed) {
    value = (critical_check_passed / (critical_check_count * 2)) * 100; // missing critical checks can not go above 50%
  } else {
    value = 50 + (regular_check_passed / (regular_check_count * 2)) * 100;
  }

  return round(value, 0);
};

export default getDocumentScore;
