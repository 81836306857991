import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Stack, Typography, Button, Popover } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import {
  getManifestChecks,
  deleteManifestCheck,
} from '@/actions/manifestCheck';
import AdminManifestCheckDrawer from './AdminManifestCheckDrawer';

function AdminManifestChecks({ manifest, global }) {
  const user = useUser();
  const configuration = useConfiguration();
  const [manifest_checks, setManifestChecks] = useState(null);
  const [loading_checks, setLoadingChecks] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selected, setSelected] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [revision, setRevision] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    setRevision((prevState) => prevState + 1);
  }, [selected]);

  useEffect(() => {
    if (user?.entity_id && !manifest_checks) {
      getManifestChecks(configuration.api, manifest?.entity_id)
        .then((data) => {
          data.sort((a, b) => {
            if (a.section === b.section) {
              return (a.name > b.name) - (a.name < b.name);
            } else {
              return (a.section > b.section) - (a.section < b.section);
            }
          });
          setManifestChecks(data);
          setLoadingChecks(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingChecks(false);
        });
    }
  }, [
    user?.entity_id,
    manifest_checks,
    setManifestChecks,
    configuration.api,
    manifest?.entity_id,
    setLoadingChecks,
  ]);

  const handleRowSelection = useCallback(
    (value) => {
      setRowSelectionModel(value);
      if (value?.length === 1) {
        const check = manifest_checks.find(
          (check) => check.entity_id === value[0]
        );
        setSelected(check);
      } else {
        setSelected(null);
      }
    },
    [setRowSelectionModel, manifest_checks, setSelected]
  );

  const handleManifestCheckUpdate = async (manifest_check) => {
    setManifestChecks((prevState) => {
      setRevision((prevState) => prevState + 1);
      return [
        ...prevState.filter(
          (check) => check.entity_id !== manifest_check.entity_id
        ),
        manifest_check,
      ].sort((a, b) => {
        if (a.section === b.section) {
          return (a.name > b.name) - (a.name < b.name);
        } else {
          return (a.section > b.section) - (a.section < b.section);
        }
      });
    });
  };

  const handleDeleteManifestCheck = useCallback(
    async (check_id) => {
      await deleteManifestCheck(
        configuration.api,
        manifest?.entity_id,
        check_id
      );
      setManifestChecks((prevState) => {
        return prevState.filter((check) => check.entity_id !== check_id);
      });
    },
    [configuration.api, manifest?.entity_id, setManifestChecks]
  );

  const deleteSelectedManifestChecks = useCallback(
    async (selected) => {
      await Promise.all(
        selected.map(async (check) => {
          await handleDeleteManifestCheck(check);
        })
      );
    },
    [handleDeleteManifestCheck]
  );

  const renderPrompt = useCallback(({ value }) => {
    return (
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
        }}
      >
        {value}
      </span>
    );
  }, []);

  const renderName = useCallback((params) => {
    return (
      // <Link component={RouterLink} to={`/admin/manifests/${params.id}`}>
      <Typography>{params.row.name}</Typography>
      // </Link>
    );
  }, []);

  const convertDate = useCallback(({ value }) => {
    if (!value) {
      return value;
    }
    return new Date(value.replace(' ', 'T') + 'Z');
  }, []);

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    if (['prompt'].includes(field)) {
      const id = event.currentTarget.parentElement.dataset.id;
      const row = manifest_checks.find((r) => r.entity_id === id);
      setValue(row[field]);
      setAnchorEl(event.currentTarget);
    } else {
      setValue(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const columns = useMemo(() => {
    const columns = global
      ? []
      : [
          {
            field: 'section',
            headerName: 'Section',
            type: 'string',
            flex: 0.5,
          },
        ];
    columns.push(
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        type: 'string',
        renderCell: renderName,
      },
      {
        field: 'critical',
        headerName: 'Critical',
        flex: 0.25,
        type: 'boolean',
      },
      {
        field: 'prompt',
        headerName: 'Prompt',
        type: 'string',
        flex: 0.5,
        renderCell: renderPrompt,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      },
      {
        field: 'updated_at',
        headerName: 'Last Changed',
        type: 'dateTime',
        flex: 0.75,
        valueGetter: convertDate,
        // renderCell: renderName,
      }
    );
    return columns;
  }, [global, renderName, convertDate, renderPrompt]);

  const ManifestCheckGridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <Button
          sx={{ padding: '3px 9px' }}
          variant='outlined'
          onClick={() => {
            handleRowSelection([]);
            setDrawerOpen(true);
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <AddCircleOutlineOutlinedIcon />
            <Typography variant='button' display='block' gutterBottom>
              Add
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{ padding: '3px 9px' }}
          onClick={() => {
            setDrawerOpen(true);
          }}
          disabled={rowSelectionModel.length !== 1}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <EditOutlinedIcon />
            <Typography variant='button' display='block' gutterBottom>
              Edit
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{ padding: '3px 9px' }}
          onClick={() => {
            deleteSelectedManifestChecks(rowSelectionModel);
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <DeleteOutlinedIcon />
            <Typography variant='button' display='block' gutterBottom>
              Delete
            </Typography>
          </Stack>
        </Button>
        <GridToolbarColumnsButton color='inherit' />
        <GridToolbarFilterButton color='inherit' />
        <GridToolbarDensitySelector color='inherit' />
        <GridToolbarExport color='inherit' />

        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }, [
    setDrawerOpen,
    deleteSelectedManifestChecks,
    handleRowSelection,
    rowSelectionModel,
  ]);

  if (loading_checks) {
    return <LoadingMask />;
  }
  console.log(revision);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <DataGrid
              pageSizeOptions={[10, 20, 50]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 20, page: 0 },
                },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    prompt: false,
                  },
                },
              }}
              autoHeight
              checkboxSelection
              columnHeaderHeight={35}
              getRowId={(row) => row.entity_id}
              columns={columns}
              rows={manifest_checks || []}
              onSelectionModelChange={handleRowSelection}
              onRowSelectionModelChange={handleRowSelection}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: ManifestCheckGridToolbar }}
              slotProps={{
                cell: {
                  onMouseEnter: handlePopoverOpen,
                  onMouseLeave: handlePopoverClose,
                },
                toolbar: {
                  sx: {
                    button: {
                      color: 'black',
                    },
                    svg: {
                      color: 'black',
                    },
                  },
                  showQuickFilter: true,
                },
              }}
            />
            <Popover
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>{value}</Typography>
            </Popover>
          </Box>
        </Box>
      </Box>

      <AdminManifestCheckDrawer
        key={`manifest-check-${selected?.entity_id}-${revision}`}
        open={drawerOpen}
        toggleDrawer={() => {
          setDrawerOpen(false);
        }}
        manifest_check={selected}
        manifest_id={manifest?.entity_id}
        setManifestCheck={handleManifestCheckUpdate}
        global={global}
      />
    </>
  );
}

export default AdminManifestChecks;
