import React from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';

const UploadButton = ({ tooltip, name, onChange, ...rest }) => {
  let input;
  return (
    <>
      <Tooltip
        title={<Typography variant='body2'>{tooltip}</Typography>}
        placement='right'
      >
        <span>
          {name ? (
            <LoadingButton
              {...rest}
              onClick={(e) => {
                e.preventDefault();
                input.click();
              }}
              endIcon={<UploadFileIcon />}
            >
              {name}
            </LoadingButton>
          ) : (
            <Button
              {...rest}
              onClick={(e) => {
                e.preventDefault();
                input.click();
              }}
            >
              <UploadFileIcon />
            </Button>
          )}
        </span>
      </Tooltip>
      <input
        ref={(element) => (input = element)}
        hidden
        onChange={(e) => {
          e.preventDefault();
          onChange(e);
        }}
        type='file'
      />
    </>
  );
};
export default UploadButton;
