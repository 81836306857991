import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Toolbar, IconButton } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useUser } from '@/providers/UserContext';
import LoadingMask from '@/components/shared/LoadingMask';

import { useConfiguration } from '@/providers/ConfigurationContext';
import { getManifest } from '@/actions/manifest';
import AdminManifestChecks from '../AdminManifests/AdminManifest/AdminManifestChecks';

function AdminGlobalChecks() {
  const user = useUser();
  const configuration = useConfiguration();
  const navigate = useNavigate();
  const [manifest, setManifest] = useState(null);
  const [loading_manifest, setLoadingManifest] = useState(true);

  useEffect(() => {
    if (user?.entity_id && !manifest) {
      getManifest(configuration?.api, 'global')
        .then((data) => {
          setManifest(data);
          setLoadingManifest(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingManifest(false);
        });
    }
  }, [
    configuration?.api,
    user?.entity_id,
    manifest,
    setManifest,
    setLoadingManifest,
  ]);

  if (loading_manifest) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant='h6' sx={{ mr: 4 }}>
          Document Control Checks
        </Typography>
      </Toolbar>
      <Divider />

      <Box padding='15px'>
        <AdminManifestChecks manifest={manifest} global />
      </Box>
    </>
  );
}

export default AdminGlobalChecks;
