import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';

import getFile from '@/util/getFile';
import { convertToMarkdown } from '../../../actions/document';
import { useConfiguration } from '../../../providers/ConfigurationContext';
import UploadButton from '../UploadButton';
import ErrorSnackBar from '@/components/shared/ErrorSnackBar';

const FORMATS = {
  DOCX: 'docx',
  TEXT: 'text',
  XLSX: 'xlsx',
  CSV: 'csv',
};

const DOCUMENT_TYPES = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FORMATS.DOCX,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    FORMATS.XLSX,
  'text/plain': FORMATS.TEXT,
  'application/json': FORMATS.TEXT,
  'text/csv': FORMATS.CSV,
  'text/markdown': FORMATS.TEXT,
};

function DocumentUploader({ setDocuments, disabled }) {
  const configuration = useConfiguration();
  const [doc_content, setDocContent] = useState('');
  const [document_error, setDocumentError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleUpload = useCallback(
    async (e) => {
      if (e.target.files[0]) {
        setLoading(true);
        const document = await getFile(e.target.files[0]);
        if (document) {
          const processed = { name: document.name };
          const title = document.name;
          const type = DOCUMENT_TYPES[document.type];
          if (!type) {
            setDocumentError('Unsupported file format');
            return;
          }
          const markdown = await convertToMarkdown(
            configuration.api,
            title,
            type,
            document.base64contents.split(',')[1],
            undefined,
            ['table_conversion']
          );
          processed.contents = markdown.contents;
          processed.processed_contents = markdown.processed_contents;
          setDocContent(processed.contents);
          setDocuments([processed]);
        }
        setLoading(false);
      }
    },
    [setDocuments, setDocumentError, setDocContent, configuration.api]
  );

  return (
    <Box>
      <UploadButton
        onChange={handleUpload}
        variant='outlined'
        name={'Upload Document'}
        loading={loading}
        disabled={disabled}
        tooltip={
          Boolean(doc_content)
            ? 'Upload again to replace document'
            : 'Upload a Document for Evaluation'
        }
      />
      <ErrorSnackBar
        message={document_error}
        onClear={() => {
          setDocumentError(undefined);
        }}
      />
    </Box>
  );
}

export default DocumentUploader;
