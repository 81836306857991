import React, { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  Divider,
  Button,
  Toolbar,
  Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import { useUser } from '@/providers/UserContext';
import { useConfiguration } from '@/providers/ConfigurationContext';
import { useDocTypes } from '@/providers/DocTypesContext';
import LoadingMask from '@/components/shared/LoadingMask';
import AdminManifestList from './AdminManifestList';
import { useLocation, useNavigate } from 'react-router-dom';
import ManifestDeleteDialog from './ManifestDeleteDialog';
import { deleteManifest } from '@/actions/manifest';

function AdminManifests() {
  const doc_types = useDocTypes();
  const navigate = useNavigate();
  const location = useLocation();
  const configuration = useConfiguration();
  const [document_types, setDocumentTypes] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('type') || 'pending';

  useEffect(() => {
    if (doc_types && !document_types) {
      const maxLevel = Math.max(...doc_types.map((doc_type) => doc_type.level));
      const filteredArray = doc_types.filter(
        (doc_type) => doc_type.level >= maxLevel - 1
      );
      setDocumentTypes(filteredArray);
    }
  }, [doc_types, document_types, setDocumentTypes]);

  const handleTabChange = (event, newValue) => {
    navigate(`?type=${newValue}`);
  };

  const handleDeleteManifest = async (manifest_id) => {
    await deleteManifest(configuration.api, manifest_id);
    setDeleteOpen(false);
  };

  if (!document_types) {
    return <LoadingMask />;
  }

  return (
    <>
      <Toolbar
        sx={{
          minHeight: '55px !important',
        }}
      >
        <Typography variant='h6' sx={{ mr: 4 }}>
          Manage Manifests
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            onClick={() => {
              navigate({
                pathname: '/admin/manifests/edit',
                search: tab !== 'pending' ? `?type=${tab}` : '',
              });
            }}
            variant='contained'
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            New Manifest
          </Button>
          <Button
            onClick={() => setDeleteOpen(true)}
            disabled={selected.length !== 1}
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </Stack>
      </Toolbar>
      <Divider />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label='Manage Manifests'>
              <Tab label='Pending Review' value='pending' />
              {document_types.map((document_type, i) => {
                return (
                  <Tab
                    key={document_type.name}
                    label={document_type.name}
                    value={`${document_type.code}`}
                  />
                );
              })}
            </TabList>
          </Box>
          <TabPanel key='pending' value='pending'>
            <AdminManifestList pending_only setSelected={setSelected} />
          </TabPanel>
          {document_types.map((document_type, i) => {
            return (
              <TabPanel
                key={`${document_type.code}`}
                value={`${document_type.code}`}
              >
                <AdminManifestList
                  document_type_id={document_type.entity_id}
                  setSelected={setSelected}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
      <ManifestDeleteDialog
        manifest_id={selected[0]}
        deleteManifest={handleDeleteManifest}
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
      />
    </>
  );
}

export default AdminManifests;
