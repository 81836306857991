import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Accordion,
  Typography,
  AccordionSummary,
  Stack,
  AccordionDetails,
} from '@mui/material';

import Semaphore from '@/util/Semaphore';
import LoadingMask from '@/components/shared/LoadingMask';
import { useConfiguration } from '@/providers/ConfigurationContext';
import { getDocumentAsset } from '@/actions/documentAsset';
import INSPECTION_TYPES from '@/components/inspectionTypes';
import {
  getInspectionChecks,
  updateInspectionCheck,
} from '@/actions/inspectionCheck';
import { runManifestCheck } from '@/actions/manifestCheck';
import { getInspectionAsset } from '@/actions/inspectionAsset';
import RunningInspectionDocumentChecks from './RunningInspectionDocumentChecks';
import INSPECTION_STATUSES from '../../../../inspectionStatuses';
import { updateInspection } from '@/actions/inspection';
import CHECK_RESULT_STATUSES from '../checkResultStatuses';

import wait from '@/util/wait';

const response_re =
  /^(?<status>FAILED|PASSED)\s*\*Analysis\*[:\s]*(?<rationale>[\s\S]*)\s*\*Suggestions for Improvements{0,1}\*[:\s]*(?<suggestion>[\s\S]*)$/;

const MAX_CONCURRENT_CHECKS = 5;
const PASSED = /^PASSED/;
const FAILED = /^FAILED/;

const calculateStatus = (final_result) => {
  if (!final_result?.generated_text) {
    return CHECK_RESULT_STATUSES.UNKNOWN;
  }
  if (final_result.generated_text.match(PASSED)) {
    return CHECK_RESULT_STATUSES.PASSED;
  }
  if (final_result.generated_text.match(FAILED)) {
    return CHECK_RESULT_STATUSES.FAILED;
  }
  return CHECK_RESULT_STATUSES.UNKNOWN;
};

async function getInspectionDocumentsWithChecks(api, inspection) {
  const inspection_checks = await getInspectionChecks(
    api,
    inspection.entity_id
  );

  if (inspection.type === INSPECTION_TYPES.EFFECTIVE) {
    const inspection_documents = await Promise.all(
      inspection.documents.map(async (document) => {
        const doc_content = await getDocumentAsset(
          api,
          document.document_id,
          document.document_content_id
        );
        return {
          ...document,
          doc_content,
          checks: inspection_checks
            .filter((check) => check.document_id === document.document_id)
            .sort((a, b) => {
              if (a.check_section === b.check_section) {
                return (
                  (a.check_name > b.check_name) - (a.check_name < b.check_name)
                );
              } else {
                if (a.check_section === 'global') {
                  return -1;
                } else if (b.check_section === 'global') {
                  return 1;
                } else {
                  return (
                    (a.check_section > b.check_section) -
                    (a.check_section < b.check_section)
                  );
                }
              }
            }),
        };
      })
    );
    return inspection_documents;
  } else {
    const doc_content = await getInspectionAsset(
      api,
      inspection.entity_id,
      inspection.doc_content_id
    );
    return [
      {
        doc_content: doc_content.contents,
        checks: inspection_checks.sort((a, b) => {
          if (a.check_section === b.check_section) {
            return (
              (a.check_name > b.check_name) - (a.check_name < b.check_name)
            );
          } else {
            if (a.check_section === 'global') {
              return -1;
            } else if (b.check_section === 'global') {
              return 1;
            } else {
              return (
                (a.check_section > b.check_section) -
                (a.check_section < b.check_section)
              );
            }
          }
        }),
      },
    ];
  }
}

function RunningInspection({ inspection, setInspection }) {
  const configuration = useConfiguration();
  const [inspection_documents, setInspectionDocuments] = useState(null);
  const [running_document, setRunningDocument] = useState(null);
  const [inspection_manifests, setInspectionManifests] = useState(null);
  const [inspection_queue, setInspectionQueue] = useState(null);

  useEffect(() => {
    if (!inspection_documents) {
      getInspectionDocumentsWithChecks(configuration.api, inspection)
        .then((data) => {
          setInspectionDocuments(data);
        })
        .catch((error) => console.log(error));
    }
  }, [
    inspection_documents,
    inspection,
    configuration.api,
    setInspectionDocuments,
  ]);

  useEffect(() => {
    if (!inspection_manifests) {
      (async () => {
        const manifests = await Promise.all(
          inspection.manifests.map(async (manifest) => {
            if (
              manifest.reference_document_id &&
              manifest.reference_document_content_id
            ) {
              const reference_doc_content = await getDocumentAsset(
                configuration.api,
                manifest.reference_document_id,
                manifest.reference_document_content_id
              );
              return { ...manifest, reference_doc_content };
            } else {
              return { ...manifest, reference_doc_content: '' };
            }
          })
        );
        setInspectionManifests(manifests);
      })();
    }
  }, [
    inspection_manifests,
    inspection.manifests,
    configuration.api,
    setInspectionManifests,
  ]);

  useEffect(() => {
    if (
      inspection.status === INSPECTION_STATUSES.PENDING &&
      inspection_documents
    ) {
      updateInspection(configuration.api, inspection.entity_id, {
        status: INSPECTION_STATUSES.RUNNING,
      })
        .then((data) => {
          setInspection(data);
          setRunningDocument(0);
          setInspectionQueue(inspection_documents);
        })
        .catch((error) => console.log(error));
    }
    if (
      inspection_documents &&
      inspection.status === INSPECTION_STATUSES.RUNNING &&
      running_document === null
    ) {
      setRunningDocument(0);
      setInspectionQueue(inspection_documents);
    }
    if (
      inspection.status === INSPECTION_STATUSES.RUNNING &&
      running_document === -1
    ) {
      let compliant = 0,
        non_compliant = 0;
      inspection_documents.forEach((inspection_document) => {
        inspection_document.checks.forEach((check) => {
          if (check.compliant) {
            compliant++;
          } else {
            non_compliant++;
          }
        });
      });
      updateInspection(configuration.api, inspection.entity_id, {
        status: INSPECTION_STATUSES.COMPLETE,
        compliant,
        non_compliant,
      })
        .then((data) => {
          setInspection(data);
        })
        .catch((error) => console.log(error));
    }
  }, [
    running_document,
    inspection.status,
    inspection.entity_id,
    configuration.api,
    inspection_documents,
    setInspection,
    setRunningDocument,
    setInspectionQueue,
  ]);

  const runCheck = useCallback(
    async (
      manifest_id,
      manifest_check_id,
      doc_content,
      reference_doc_content
    ) => {
      const response = await runManifestCheck(
        configuration.api,
        manifest_id,
        manifest_check_id,
        doc_content,
        reference_doc_content
      );

      const reader = response.body.getReader();
      let line = '',
        finish_reason,
        answer = '';

      try {
        while (true) {
          const { done, value } = await reader.read();
          line += new TextDecoder().decode(value);
          if (line.endsWith('\n')) {
            const lines = line
              .trim()
              .split('\n')
              .filter((line) => line);
            for (let i = 0; i < lines.length; i++) {
              const data = lines[i].substring(5).trim();
              if (!data || data === '[DONE]') {
                continue;
              } else {
                try {
                  const result = JSON.parse(data);
                  if (result?.choices) {
                    if (result?.choices[0]?.finish_reason) {
                      finish_reason = result?.choices[0]?.finish_reason;
                    }
                    if (
                      !configuration.model.llm.eos_token.includes(
                        result?.choices[0]?.delta?.content
                      )
                    ) {
                      answer += result?.choices[0]?.delta?.content;
                    }
                  } else {
                    if (!result.generated_text) {
                      answer += result.token.text;
                    }
                  }
                } catch (error) {
                  console.log('Error parsing', lines[i]);
                  throw error;
                }
              }
            }
            line = '';
          }
          if (done) {
            break;
          }
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
      const final_status = calculateStatus({
        generated_text: answer.trim(),
        finish_reason,
      });
      return { answer, final_status };
    },
    [configuration.api, configuration.model.llm.eos_token]
  );
  const handleUpdateInspectionCheck = useCallback(
    async (document_id, check, update) => {
      const updated_check = await updateInspectionCheck(
        configuration.api,
        inspection.entity_id,
        check.entity_id,
        update
      );
      setInspectionDocuments((prevState) => {
        const document_index = prevState.findIndex(
          (doc) => doc.document_id === document_id
        );
        const checks = [...prevState[document_index].checks];
        const index = checks.findIndex(
          (cur_check) => cur_check.entity_id === check.entity_id
        );
        const new_check = { ...checks[index], ...updated_check };
        checks[index] = new_check;
        const new_state = [...prevState];
        new_state[document_index].checks = checks;
        return new_state;
      });
    },
    [configuration.api, inspection.entity_id, setInspectionDocuments]
  );

  useEffect(() => {
    if (
      inspection.status === INSPECTION_STATUSES.RUNNING &&
      running_document !== null &&
      running_document >= 0
    ) {
      (async () => {
        const running_inspection_document = inspection_queue[running_document];

        const semaphore = new Semaphore(MAX_CONCURRENT_CHECKS);
        await Promise.all(
          running_inspection_document.checks.map(async (check) => {
            if (
              [
                INSPECTION_STATUSES.PENDING,
                INSPECTION_STATUSES.RUNNING,
              ].includes(check.status)
            ) {
              await semaphore.run(async () => {
                await handleUpdateInspectionCheck(
                  running_inspection_document.document_id,
                  check,
                  {
                    status: INSPECTION_STATUSES.RUNNING,
                  }
                );

                const manifest = inspection_manifests.find(
                  (manifest) => manifest.manifest_id === check.manifest_id
                );
                const result = await runCheck(
                  check.manifest_id,
                  check.manifest_check_id,
                  running_inspection_document.doc_content,
                  manifest?.reference_doc_content
                );
                await wait(10000);
                const match = result.answer.match(response_re);
                if (match) {
                  await handleUpdateInspectionCheck(
                    running_inspection_document.document_id,
                    check,
                    {
                      status: INSPECTION_STATUSES.COMPLETE,
                      compliant:
                        result.final_status === CHECK_RESULT_STATUSES.PASSED,
                      rationale: match.groups.rationale,
                      suggestion:
                        result.final_status !== CHECK_RESULT_STATUSES.PASSED
                          ? match.groups.suggestion
                          : undefined,
                    }
                  );
                } else {
                  await handleUpdateInspectionCheck(
                    running_inspection_document.document_id,
                    check,
                    {
                      status: INSPECTION_STATUSES.ERRORED,
                      rationale: result.answer,
                    }
                  );
                }
              });
            }
          })
        );
        if (running_document === inspection_queue.length - 1) {
          setRunningDocument(-1);
        } else {
          setRunningDocument((prevState) => {
            return prevState + 1;
          });
        }
      })();
    }
  }, [
    runCheck,
    inspection.status,
    running_document,
    inspection_queue,
    inspection_manifests,
    setInspectionDocuments,
    handleUpdateInspectionCheck,
  ]);

  if (!inspection_documents) {
    return <LoadingMask />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {inspection_documents.map((document, i) => {
        return (
          <Accordion
            key={document.document_id || 'draft_document'}
            expanded={i === running_document}
          >
            {document.document_name ? (
              <AccordionSummary>
                <Stack direction='row' spacing={2} alignItems={'center'}>
                  <Typography variant='h6' paragraph gutterBottom>
                    {document.document_name}
                  </Typography>
                  <Typography variant='body1' paragraph gutterBottom>
                    {i === running_document
                      ? 'Running'
                      : i > running_document
                      ? 'Pending'
                      : 'Complete'}
                  </Typography>
                </Stack>
              </AccordionSummary>
            ) : undefined}
            <AccordionDetails>
              <RunningInspectionDocumentChecks
                inspection={inspection}
                checks={document.checks}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default RunningInspection;
