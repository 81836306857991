import React, { useEffect, useState } from 'react';
import { Tab, Box, Divider, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';

import { useConfiguration } from '@/providers/ConfigurationContext';
import { getDocumentAsset } from '@/actions/documentAsset';
import InspectionDocumentManifestChecks from './InspectionDocumentManifestChecks';
import INSPECTION_STATUSES from '../../../../inspectionStatuses';
import InspectionResultGauge from './InspectionResultGauge';

function InspectionDocument({ inspection, document, manifests }) {
  const configuration = useConfiguration();
  const [doc_content, setDocContent] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (document?.document_content_id && !doc_content) {
      getDocumentAsset(
        configuration.api,
        document.document_id,
        document.document_content_id
      )
        .then((data) => {
          setDocContent(data);
        })
        .catch((error) => console.log(error));
    }
  }, [document, doc_content, configuration.api, setDocContent]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  if (document) {
    return (
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          component='dl' // mount a Definition List
          spacing={0}
          sx={{ display: 'table' }}
        >
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Document Name:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {document?.document_name}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              component='dt'
              variant='body1'
              sx={{ display: 'table-cell', width: '250px' }}
            >
              <b>Document ID:</b>
            </Typography>
            <Typography
              component='dd'
              variant='body2'
              sx={{ display: 'table-cell' }}
            >
              {document?.document_doc_id}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {inspection?.status === INSPECTION_STATUSES.COMPLETE ? (
          <InspectionResultGauge
            critical_non_compliant={document?.critical_non_compliant}
            non_critical_non_compliant={document?.non_critical_non_compliant}
            critical_compliant={document?.critical_compliant}
            non_critical_compliant={document?.non_critical_compliant}
          />
        ) : undefined}
        <Divider />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label='Manage Manifests'>
                {(manifests || []).map((manifest, i) => {
                  return (
                    <Tab key={manifest.name} label={manifest.name} value={i} />
                  );
                })}
              </TabList>
            </Box>

            {(manifests || []).map((manifest, i) => {
              return (
                <TabPanel key={`${i}`} value={i}>
                  <InspectionDocumentManifestChecks
                    inspection={inspection}
                    document={document}
                    manifest={manifest}
                  />
                </TabPanel>
              );
            })}
          </TabContext>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: '100%' }}>
        {inspection?.status === INSPECTION_STATUSES.COMPLETE ? (
          <InspectionResultGauge
            critical_non_compliant={inspection?.critical_non_compliant}
            non_critical_non_compliant={inspection?.non_critical_non_compliant}
            critical_compliant={inspection?.critical_compliant}
            non_critical_compliant={inspection?.non_critical_compliant}
          />
        ) : undefined}
        <Divider />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label='Manage Manifests'>
                {(manifests || []).map((manifest, i) => {
                  return (
                    <Tab key={manifest.name} label={manifest.name} value={i} />
                  );
                })}
              </TabList>
            </Box>

            {(manifests || []).map((manifest, i) => {
              return (
                <TabPanel key={`${i}`} value={i}>
                  <InspectionDocumentManifestChecks
                    inspection={inspection}
                    manifest={manifest}
                  />
                </TabPanel>
              );
            })}
          </TabContext>
        </Box>
      </Box>
    );
  }
}

export default InspectionDocument;
