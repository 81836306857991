import { countTokens } from '@/actions/tokenize';

const buildMessage = async (api, model_config, prompt, docs, image_url) => {
  const [prompt_tokens, doc_tokens] = await Promise.all([
    countTokens(api, prompt),
    Promise.all(
      (docs || []).map(async (doc) => {
        const tokens = await countTokens(
          api,
          // `\n\n###${doc.title}${
          //   doc.page_number ? ` - page ${doc.page_number}` : ''
          // }\n${doc.content}`
          `\n\n${doc.content}`
        );
        return {
          content: doc.content,
          title: doc.title,
          page_number: doc.page_number,
          tokens: tokens,
        };
      })
    ),
  ]);

  let token_count = model_config.prompt_template_tokens + prompt_tokens;

  let content = '';

  if (docs?.length) {
    content += `>>SUMMARY<<\n`;

    for (let i = 0; i < doc_tokens.length; i++) {
      const doc = doc_tokens[i];
      console.log(doc);
      if (token_count + doc.tokens < model_config.max_input_length) {
        if (i === 0) {
          content += `${doc.content}`;
          // `###${doc.title}${
          //   doc.page_number ? ` - page ${doc.page_number}` : ''
          // }\n${doc.content}`;
        } else {
          content += `\n\n${doc.content}`;
          // `\n\n###${doc.title}${
          //   doc.page_number ? ` - page ${doc.page_number}` : ''
          // }\n${doc.content}`;
        }
        token_count += doc.tokens;
      }
    }
  }

  content += `\n\n>>QUESTION<<\n${prompt}`;

  if (image_url) {
    return {
      name: 'User',
      role: 'user',
      content: [
        { type: 'text', text: content },
        {
          type: 'image_url',
          image_url: {
            url: image_url,
          },
        },
      ],
      content_tokens: token_count,
    };
  }
  return {
    name: 'User',
    role: 'user',
    content,
    content_tokens: token_count,
  };
};
export default buildMessage;
