import React, { useState, useEffect } from 'react';

import { Box, TextField, Autocomplete } from '@mui/material';
import { useDocTypes } from '@/providers/DocTypesContext';
import INSPECTION_TYPES from '@/components/inspectionTypes';
function InspectionDocumentTypeField({
  inspection_type,
  value,
  setValue,
  setChanged,
  edit,
}) {
  const doc_types = useDocTypes();

  const [selected, setSelected] = useState(value);
  const [options, setOptions] = useState(null);

  const handleChange = (event, newValue) => {
    setChanged();
    setSelected(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (doc_types && !options) {
      const maxLevel = Math.max(...doc_types.map((doc_type) => doc_type.level));
      const filteredArray = doc_types.filter(
        (doc_type) => doc_type.level >= maxLevel - 1
      );
      setOptions(filteredArray);

      if (value) {
        const full_value = filteredArray.find(
          (doc_type) => doc_type.entity_id === value.entity_id
        );
        setSelected(full_value);
        setValue(full_value);
      }
    }
  }, [doc_types, options, value, setValue, setOptions, setSelected]);

  return (
    <Box>
      <Autocomplete
        id='document-types-field'
        options={options || []}
        value={selected}
        sx={{ width: '100%' }}
        disabled={edit}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name}`}
        disableClearable={true}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={
              inspection_type === INSPECTION_TYPES.EFFECTIVE
                ? 'Document Type to Inspect'
                : 'Uploaded Document Type'
            }
          />
        )}
      />
    </Box>
  );
}

export default InspectionDocumentTypeField;
